body {
  font-family: $bodytext-font-family;
  color: $bodytext-font-colour;
  font-size: 20px;
  line-height: 30px;
  background: $page-bg-colour;
  padding-top: 50px;
  position: relative;
}

abbr {
  border-bottom: 1px dotted #333;
}

.section-heading {
  margin-bottom: 20px;
  color: $section-heading-colour;

  @media (min-width: $screen-sm-min) {
    text-align: center;
    margin-bottom: 40px;
  }

  &__icon {
    background-image: url(../img/grey-pen.png);
    background-size: 36px 36px;
    display: inline-block;
    width: 36px;
    height: 36px;
    position: relative;
    top: 3px;
    margin-right: 6px;
  }
}

.section-subheading {
  color: $section-heading-colour;
  margin-bottom: 20px;
}

.section-intro {
  margin-bottom: 30px;

  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.page-section {
  padding: 20px 0;

  a:link,
  a:visited,
  a:active {
    color: $mid-section-text-colour;
    border-bottom: 1px dotted $mid-section-text-colour;

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom: 1px solid $mid-section-text-colour;
    }
  }

  p {
    margin-bottom: 16px;
  }

  @media (min-width: $screen-sm-min) {
    padding: 40px 0;
  }
}

.intro-section {
  background-color: $dark-section-bg-colour;
  color: $dark-section-text-colour;
  text-align: center;
  position: relative;

  &__bg {
    background-color: $dark-section-bg-colour;
    background-image: url(../img/bookshelf.jpg);
    background-repeat: repeat-x;
    background-position: 0 bottom;
    width: 100%;
    height: 100px;
    z-index: 1;
    position: absolute;
    bottom: 2px;
  }

  &__container {
    margin-top: 30px;
    margin-bottom: 120px;
    position: relative;
    z-index: 10;
  }

  &__title {
    font-family: $brand-font-family;
    font-size: 60px;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: 22px;
    font-weight: normal;
    margin-top: 6px;
    color: $dark-section-subtitle-text-colour;
  }

  &__headshot {
    width: 150px;
    height: 150px;
    margin: 20px auto;

    & img {
      border: 6px solid #ffffff;
    }
  }

  &__intro {
    text-align: center;
    font-size: 22px;
  }
}

.services-section {
  abbr {
    border-bottom: 1px dotted inherit;
  }
}

.experience-section {
  background-color: $mid-section-bg-colour;
  color: $mid-section-text-colour;
  border-top: 1px solid $mid-section-border-colour;
  border-bottom: 1px solid $mid-section-border-colour;

  &__experience,
  &__qualifications {
    padding-left: 20px;
  }

  &__qualifications {
    li {
      margin-bottom: 10px;

      .qualification-body {
        font-style: italic;
        display: block;
        padding-left: 6px;
        color: $section-heading-colour;
      }
    }
  }
}

.ciep-logo {
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto 0 auto;
}

.sfa-logo {
  width: 36px;
  height: 36px;
  display: inline-block;
  margin: -20px 0 0 12px;
  filter: grayscale(100%);
}

.book-title {
  font-style: italic;
}

.carousel-inner {
  height: 800px;

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 768px) {
    height: 280px;
  }
}

.testimonials-section {
  display: none;
  background-color: $dark-section-bg-colour;
  color: $dark-section-text-colour;

  @media (min-width: 768px) {
    display: block;
  }

  &__testimonial {
    position: relative;

    &__quote {
      position: absolute;
      color: #8f8f8f;

      &--left {
        top: 10px;
        left: 0;
      }

      &--right {
        bottom: 40px;
        right: 10px;
      }
    }

    &__text {
      border-left: none;
      margin: 0 30px;
    }

    &__attribution {
      color: $dark-section-subtitle-text-colour;
      margin-top: 10px;
    }
  }
}

.cost-section {
  &__required_info {
    padding-left: 20px;
  }
}

.contact-section {
  background-color: $mid-section-bg-colour;
  color: $mid-section-text-colour;
  border-top: 1px solid $mid-section-border-colour;

  &__options {
    @media (min-width: $screen-sm-min) {
      text-align: center;
    }
  }

  &__social {
    margin-top: 40px;

    & a:link,
    & a:visited,
    & a:active {
      border-bottom: none;

      &:hover,
      &:focus {
        border-bottom: none;
        color: $BRAND_COLOUR;
      }
    }
    &__twitter,
    &__linkedin {
      display: inline-block;
    }

    &__twitter {
      padding-right: 10px;
    }
  }
}

.main-footer {
  background-color: $dark-section-bg-colour;
  color: $dark-section-text-colour;
  font-size: 0.8em;
  padding: 10px 0;
  text-align: right;

  & a {
    color: #ffffff;
    text-decoration: underline;
  }
}
