$BRAND_COLOUR: #283232;
$BRAND_SECONDARY_COLOUR: #8e8e8e;

$page-bg-colour: #ffffff;

$bodytext-font-family: 'Open Sans', sans-serif;
$bodytext-font-colour: rgba(0,0,0,0.6);

$section-heading-colour: #212326;

$dark-section-bg-colour: #283232;
$dark-section-text-colour: #ffffff;
$dark-section-subtitle-text-colour: #dddddd;

$mid-section-bg-colour: #f5f5f5;
$mid-section-text-colour: $bodytext-font-colour;
$mid-section-border-colour: #cccccc;

$brand-font-family: 'Dancing Script', cursive;
$brand-font-colour:$BRAND_COLOUR;
$brand-subtitle-colour: $BRAND_SECONDARY_COLOUR;


