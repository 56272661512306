.navbar-default {
    background-color: #ffffff;
    font-size: 14px;

    @media (min-width: $screen-md-min) {
        font-size: 18px;
    }

    .navbar-nav > li > a {
        color: #adadad;
        text-transform: uppercase;
        @include transition(all 0.3s);

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            color: #373737;
        }
    }

    .navbar-nav > .active > a {
        background: transparent;

        &:hover,
        &:active,
        &:focus {
            background: transparent;
        }
    }

    .navbar-brand {
        color: $brand-font-colour;
        font-family: $brand-font-family;
    }

    .navbar-toggle {
        border: none;

        .icon-bar {
            background-color: $BRAND_COLOUR;
        }
    }
}

.navbar-fixed-top {
    border-bottom: none;
    @include transition(padding 0.3s);
}

.navbar-brand {
    font-size: 30px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 48px;
}

.navbar-tagline {
    float: left;
    padding-top: 4px;
    letter-spacing: 0.015em;
    font-size: 16px;
    line-height: 48px;
    color: $brand-subtitle-colour;
    display: none;

    @media (min-width: $screen-md-min) {
        display: block;
    }
}

.navbar-brand,
.navbar-tagline {
    @include opacity(0);
    @include transition(opacity 0.3s);
}

.navbar-fixed-top--compact {
    border-bottom: 1px #8e8e8e;
    box-shadow: -1px 4px 18px -3px rgba(0,0,0,0.07);
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    min-height: 48px;
    font-size: 14px;
    @include transition(all 0.3s);

    @media (min-width: $screen-md-min) {
        font-size: 14px;
    }

    .navbar-nav > li > a {
        padding-top: 14px;
        padding-bottom: 14px;
        @include transition(padding 0.3s);
    }

    .navbar-brand,
    .navbar-tagline {
        @include opacity(1);
        @include transition(opacity 0.3s);
    }
}

.section-anchor {
    display: block;
    position: relative;
    visibility: hidden;    
    top: -60px;
}